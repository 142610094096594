import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/resources-banner-bg.jpg"
import ResourcesTabs from "../components/shared/resources-tabs"
import ResourcesTab from "../components/resources/resources-tab"

const ResourcesPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Resources" />
        <InnerBanner theme="resources nowave" image={banner} title="Resources" />

        <ResourcesTabs>
            <div label="Vets">
                <ResourcesTab.Tab1 />
            </div>
            <div label="Links">
                <ResourcesTab.Tab2 />
            </div>
        </ResourcesTabs>


        <HowToHelp />
    </Layout>
}

export default ResourcesPage