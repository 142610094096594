import React, { useState } from "react"
import "./resources-tabs.css"

const TabItem = ({children,isActive}) => {


    return  <div className={"tab-content "+(isActive?"active":"")}>
            {children}
        </div>
}
const ResourcesTabs = ({ children }) => {
    const [active, setActive] = useState(0);

    return <div className="resources-tabs">
        <ul>
            {children && children.map((child, key) => {
                return <li key={key} className={active === key ? "active" : ""}><button onClick={() => {
                    setActive(key);
                }}>{child.props.label}</button></li>
            })}
        </ul>

        {children && children.map((child, key) => {
            return <TabItem index={key} isActive={active === key}>
                {child.props.children}
            </TabItem>
        })}
    </div>
}

export default ResourcesTabs